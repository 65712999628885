@import "~@/assets/scss/global.scss";





























































.el-card {
  width: 218px;
  display: inline-block;
  margin-right: 10px;

  img {
    width: 218px;
    height: 218px;
    object-fit: contain;
  }

  .desc {
    width: 218px;
    height: 94px;
    padding: 10px 16px;
    box-sizing: border-box;

    .other {
      margin-bottom: 10px;
      position: relative;

      :first-child {
        color: #ff4200;
        font-size: 22px;
        font-weight: 500;

        .dollar-sign {
          font-size: 15px;
        }
      }

      :last-child {
        position: absolute;
        right: 0;
        top: 3px;
        color: #666666;
        font-size: 13px;
        font-weight: 400;
      }
    }

    .title {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
